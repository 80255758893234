import { CardSkeleton } from '@/components/Card/CardSkeleton';

interface DashboardViewSkeletonProps {
    readonly title?: string;
}

export function DashboardViewSkeleton({ title }: DashboardViewSkeletonProps) {
    return (
        <>
            <h1 className="font-semibold text-2xl">{title ?? 'Panel principal'}</h1>
            <div className="grid grid-cols-3 gap-8 py-4 max-w-7xl">
                {Array.from(Array(9).keys()).map((index) => (
                    <CardSkeleton key={index} />
                ))}
            </div>
        </>
    );
}
