import { CatalogueVehicleSubstatusStatistics, DealStagesStatistics } from '@/types/statistics';

export const transformStagesToObject = (stages: DealStagesStatistics[]): Record<string, number> => {
    return stages.reduce(
        (acc, stage) => {
            acc[stage.stage] = stage.count;
            return acc;
        },
        {} as Record<string, number>,
    );
};

export const transformSubstatusesToObject = (
    substatuses: CatalogueVehicleSubstatusStatistics[],
): Record<string, number> => {
    return substatuses.reduce(
        (acc, substatus) => {
            acc[substatus.subStatus] = substatus.count;
            return acc;
        },
        {} as Record<string, number>,
    );
};
