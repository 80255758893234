import { mapNaN } from '@/lib/utils/values';
import { SelectOption, tiers } from '@/types';
import { Statistics } from '@/types/statistics';
import { CatalogueVehicleSubStatuses } from '@/types/Vehicle';
import { FilterProcessorDashboardForm } from '@/views/dashboard/forms';
import { transformSubstatusesToObject } from '@/views/dashboard/helpers';
import { C2CAcquisitionStageStatistics, DashboardTable } from '@/views/dashboard/tables/DashboardTable';
import { useState } from 'react';
import { HiOutlineClock, HiPhoneIncoming } from 'react-icons/hi';
import { HiCheckCircle, HiClock } from 'react-icons/hi2';
import { DashboardRedirectProps } from '@/views/dashboard/types/DashboardRedirectProps';

export interface C2CAcquisitionSectionProps {
    readonly data: Statistics;
    readonly redirectToVehicles: (props: DashboardRedirectProps) => void;
}

export function C2CAcquisitionSection({ data, redirectToVehicles }: C2CAcquisitionSectionProps) {
    const [processor, setProcessor] = useState<SelectOption<string> | null>(null);
    const vehicleSubStatuses = transformSubstatusesToObject(data.catalogueVehicles?.subStatuses);

    const handleClickCard = (subStatuses: CatalogueVehicleSubStatuses) => {
        redirectToVehicles({
            tier: tiers.C2C,
            filters: {
                situation: 'OPEN',
                subStatuses,
                ...(processor && {
                    processorAgentUuid: processor?.value,
                    label: processor?.label,
                }),
            },
        });
    };

    const contact: C2CAcquisitionStageStatistics = {
        PENDING_CONTACT: mapNaN(vehicleSubStatuses.PENDING_CONTACT),
        CONTACTED_BY_PHONE: mapNaN(vehicleSubStatuses.CONTACTED_BY_PHONE),
        CONTACTED_BY_WHATSAPP: mapNaN(vehicleSubStatuses.CONTACTED_BY_WHATSAPP),
        IN_PROCESS: mapNaN(vehicleSubStatuses.IN_PROCESS),
        SENT_TO_BOT: mapNaN(vehicleSubStatuses.SENT_TO_BOT),
    };

    const approved: C2CAcquisitionStageStatistics = {
        PENDING_DOCUMENTATION: mapNaN(vehicleSubStatuses.PENDING_DOCUMENTATION),
        DOCUMENTATION_RECEIVED: mapNaN(vehicleSubStatuses.DOCUMENTATION_RECEIVED),
        PENDING_PHOTO_EDITING: mapNaN(vehicleSubStatuses.PENDING_PHOTO_EDITING),
        PHOTO_EDITING_KO: mapNaN(vehicleSubStatuses.PHOTO_EDITING_KO),
        PENDING_EXTRAS: mapNaN(vehicleSubStatuses.PENDING_EXTRAS),
        PENDING_PUBLICATION: mapNaN(vehicleSubStatuses.PENDING_PUBLICATION),
    };

    const published: C2CAcquisitionStageStatistics = {
        PUBLISHED_STOCK: mapNaN(vehicleSubStatuses.PUBLISHED_STOCK),
        PROPOSED_REPRICING: mapNaN(vehicleSubStatuses.PROPOSED_REPRICING),
        REPRICING_ACCEPTED: mapNaN(vehicleSubStatuses.REPRICING_ACCEPTED),
        REPRICING_DENIED: mapNaN(vehicleSubStatuses.REPRICING_DENIED),
        REPRICING_SENT: mapNaN(vehicleSubStatuses.REPRICING_SENT),
    };

    return (
        <div className="space-y-8">
            <div className="flex gap-8 items-center">
                <h2 className="font-semibold text-2xl">Compras</h2>
                <FilterProcessorDashboardForm tier={tiers.C2C} setProcessor={setProcessor} />
            </div>
            <h2 className="flex gap-2 items-center p-2 bg-white rounded-lg font-semibold text-xl">
                <HiOutlineClock /> En proceso
            </h2>
            <div className="grid grid-cols-2 gap-16 p-2">
                <DashboardTable
                    handleClickCard={handleClickCard}
                    title="Contactado"
                    fields={contact}
                    icon={<HiPhoneIncoming className="w-6 h-6" />}
                />
                <DashboardTable
                    handleClickCard={handleClickCard}
                    title="Aprobado"
                    fields={approved}
                    icon={<HiClock className="w-5 h-5" />}
                />
                <DashboardTable
                    handleClickCard={handleClickCard}
                    title="Publicado"
                    fields={published}
                    icon={<HiCheckCircle className="w-6 h-6" />}
                />
            </div>
        </div>
    );
}
