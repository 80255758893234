import { StageC2C, stagesC2C, StagesC2CKeys } from '@/types';
import { CatalogueVehicleSubStatuses, catalogueVehicleSubStatuses } from '@/types/Vehicle';
import { Table } from 'flowbite-react';

export type C2CAcquisitionStageStatistics = Partial<Record<CatalogueVehicleSubStatuses, number>>;
export type C2CSalesStageStatistics = Record<StageC2C, number>;

interface DashboardTableProps {
    readonly handleClickCard: (stage: any) => void;
    readonly title: string;
    readonly fields: C2CAcquisitionStageStatistics | C2CSalesStageStatistics;
    readonly icon?: React.ReactNode;
}

export function DashboardTable({ handleClickCard, title, fields, icon }: DashboardTableProps) {
    const isAcquisition = (fields: any): fields is C2CAcquisitionStageStatistics => {
        return Object.keys(fields).some((key) => key in catalogueVehicleSubStatuses);
    };

    return (
        <div className="w-full">
            <Table striped>
                <Table.Head>
                    <Table.HeadCell className="flex items-center gap-2 rounded-tl-lg">
                        {icon} {title.toUpperCase()}
                    </Table.HeadCell>
                    <Table.HeadCell className="rounded-tr-lg" />
                </Table.Head>
                <Table.Body>
                    {Object.keys(fields).map((stage) => {
                        const value = fields[stage as keyof typeof fields] || 0;

                        return (
                            <Table.Row
                                key={stage}
                                className={`${value > 0 && 'hover:bg-blue-100 hover:cursor-pointer'}`}
                                onClick={() => {
                                    if (value > 0) handleClickCard(stage);
                                }}
                            >
                                <Table.Cell>
                                    {isAcquisition(fields)
                                        ? catalogueVehicleSubStatuses[stage as CatalogueVehicleSubStatuses]
                                        : stagesC2C[stage as StagesC2CKeys]}
                                </Table.Cell>
                                <Table.Cell>{value}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
}
