export function mapNaN(value: number): number {
    return isNaN(value) || isNil(value) ? 0 : value;
}

export function isNil(value: any): boolean {
    return value === null || value === undefined;
}

export function isNilOrEmptyString(value: any): boolean {
    return isNil(value) || value === '';
}
