import { useApiClient } from '@/hooks/useApiClient';
import { StatisticsFilters } from '@/types/statistics';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const client = useApiClient();
const queryKey = ['dashboard'];

export function useDashboardQuery(filters?: Partial<StatisticsFilters>) {
    return useQuery({
        queryKey,
        queryFn: async () => await client.getStatistics(filters),
        refetchOnWindowFocus: false,
        retry: false,
        throwOnError: true,
    });
}

export function useDashboardUsersMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (filters: Partial<StatisticsFilters>) => await client.getStatistics(filters),
        mutationKey: queryKey,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
            const prevData = queryClient.getQueryData(queryKey);
            return { prevData };
        },
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export const useDashboardPageData = (filters?: Partial<StatisticsFilters>) => useDashboardQuery(filters);
