import { Card } from './Card';

export function CardSkeleton() {
    return (
        <Card>
            <div className="w-full divide-y bg-white transition-colors duration-200">
                <div className="h-5 animate-pulse rounded bg-gray-300 p-1" />
            </div>
        </Card>
    );
}
