import { format } from 'date-fns';

export function isEmpty(obj: object) {
    return Object.keys(obj).length === 0;
}

export function getTotalPages(items: number, itemsPerPage: number) {
    return Math.ceil(items / itemsPerPage);
}

export function uuidv4() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
}

export function parseNumber(value: string) {
    return parseFloat(
        value
            .replace(/[^\d.,]/g, '')
            .replace('.', '')
            .replace(',', '.'),
    );
}

export function removeSpaces(input: string): string {
    return input.replace(/\s+/g, '');
}

export function titleize(title: string) {
    return title[0].toUpperCase() + title.slice(1).toLowerCase();
}

export function removeAccents(str: string | null | undefined) {
    if (str) return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function formatDateWithHours(date: string | Date) {
    const dateToFormat = typeof date === 'string' ? new Date(date) : date;
    return format(dateToFormat, 'dd/MM/yyyy - HH:mm');
}

export function orUndefined(value: any) {
    return value ?? undefined;
}
