import { MdContentCopy } from 'react-icons/md';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useToast } from '@/hooks/useToast';

export type LabelWithCopyProps = {
    label: string;
    value?: string | null | undefined;
    showValue?: boolean;
    defaultValue?: string;
};

export default function LabelWithCopy({
    label,
    value,
    showValue = true,
    defaultValue = 'No disponible',
}: LabelWithCopyProps) {
    const [_, setCopy] = useCopyToClipboard();
    const { successToast, errorToast } = useToast();

    const displayValue = value || defaultValue;
    const showCopyButton = Boolean(value);
    const isUnavailable = !value;

    const handleCopy = async () => {
        if (!value) {
            errorToast('No hay nada para copiar');
            return;
        }
        await setCopy(value);
        successToast('Copiado al portapapeles');
    };

    return (
        <div className="flex flex-col sm:flex-row py-2 align-text-top">
            <span className="text-gray-600 mb-1 sm:mb-0">{label}:</span>
            <div className="flex items-start">
                {showValue && (
                    <span className={`ml-4 ${isUnavailable ? 'text-gray-400 italic' : ''}`}>{displayValue}</span>
                )}
                {showCopyButton && (
                    <MdContentCopy className="h-4 w-4 cursor-pointer text-gray-900 ml-2 mt-1" onClick={handleCopy} />
                )}
            </div>
        </div>
    );
}
